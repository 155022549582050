import React from "react";

import CustomHeader from "../header";
import CustomFooter from "../footer";

class Order extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <Chapter />
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
      <div className="page-chapters">
          <div className="wrapper-chapter">
              <div className="chapter-content">
                  <h2>Beställ</h2>

                  <h3>Swish</h3>
                  <h5>
                      Swisha 199kr till 070-5435031 så skickar vi varorna inom 10 dagar. <br />
                      Obs. Ange leveransadress i kommentarsfältet.
                  </h5>

                  <br /><br />

                  <h3>Faktura</h3>
                  <h5>
                      Önskar du att betala med faktura (+29kr) så skicka ett mail med leveransadress till info@vapenfodral.se
                  </h5>

                  <br /><br />

                  <img className="img-600"
                       src={process.env.PUBLIC_URL + "/images/logos/order.png"} alt="" />

                  <br /><br />

                  <h5>
                      Inom kort kommer ett komplett och färdigmonterat vapenfodral
                      med L-Arse Heat Seat att finnas tillgängligt för beställning <br/>
                      Anmäl intresse via  <a className="mailto" href="mailto:info@vapenfodral.se">info@vapenfodral.se</a>
                  </h5>

              </div>
          </div>
      </div>
  );
};

export default Order;

import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'

Reactotron.configure()
  .use(reactotronRedux());


if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
) {
    Reactotron.connect() // development server, connect reactotron
}

export default Reactotron

import React from "react";
import { Route, Router, Switch } from "react-router-dom";

import history from "../utils/history";

import Home from "../../view/containers/home/home";
import Order from "../../view/containers/home/order";
import Contact from "../../view/containers/home/contact";
import TermsOfPurchase from "../../view/containers/home/terms-of-purchase";

import NotFound from "../../view/containers/error/not-found";

class CustomRouter extends React.PureComponent {

  render() {
    return (
        <Router history={history}>
          <Switch>

            <Route exact path="/" component={Home} />
            <Route exact path="/order" component={Order} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/terms-of-purchase" component={TermsOfPurchase} />

            <Route component={NotFound} />

          </Switch>
        </Router>
    );
  }

}

export default CustomRouter;

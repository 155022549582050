import React from "react";
import {Link} from "react-router-dom";

class CustomFooter extends React.Component {
    render() {
        return (
            <div className="wrapper-footer">

                <div className="main-footer">
                    <div className="footer-content">

                        <div className="footer-item">
                            <span>Produkter</span>
                            <br/>
                            <Link to="/">L-Arse Heat Seat</Link>
                        </div>

                        <div className="footer-item">
                            <span>Support</span>
                            <br/>
                            <Link to="/contact/"> Kontakta oss</Link>
                        </div>

                        <div className="footer-item">
                            <span>Villkor</span>
                            <br/>
                            <Link to="/terms-of-purchase/">Köpvillkor</Link>
                        </div>

                    </div>

                </div>

                <div className="copy-footer">copyright &copy; Lars Wikman 2019</div>

            </div>
        );
    }
}

export default CustomFooter;

import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";

class Contact extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <Chapter />
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
      <div className="page-chapters">
        <div className="wrapper-chapter">
          <div className="chapter-content">

            <h2>Kontakta oss</h2>

            <p className="h2-subheader">
              Vill veta mer eller är du återförsäljare? <br/>
              Kontakta oss gärna för mer information!
            </p>

            <div className="contact-container">

              <div className="contact-item">

                <div>
                  <img className="contact-icon"
                       src={process.env.PUBLIC_URL + "/images/icons/phone-circle.svg"}
                       alt="" />
                  <h4>Telefon</h4>
                </div>

                <div>
                  <a href="tel:+46705435031">070-5435031</a>
                </div>

              </div>


              <div className="contact-item">

                <div>
                  <img className="contact-icon"
                       src={process.env.PUBLIC_URL + "/images/icons/mail-circle.svg"}
                       alt="" />
                  <h4>Mail</h4>
                </div>

                <div>
                  <a className="mailto" href="mailto:info@vapenfodral.se">info@vapenfodral.se</a>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
  );
};

export default Contact;

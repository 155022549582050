import React from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";

class CustomHeader extends React.Component {

  constructor(props) {
    super(props);

    $(document).ready(function() {
      $(".navbar-nav li a").click(function() {
        $(".navbar-collapse").collapse("hide");
      });
    });
  }

  componentDidMount() {

    window.scrollTo(0, 0);

    /*const cookies = new Cookies();
    const cookie_session = cookies.get('session');

    if(cookie_session != null) {
      this.props.actionUserGet(cookie_session);
    }*/

  }

  render() {

      return (
        <div className="header navbar navbar-expand-lg">

          <div id="header-logo">
            <NavLink className="navbar-brand mx-auto" to="/">
              Vapenfodral
            </NavLink>
          </div>

          <button className="navbar-toggler btn" type="button"
                  data-toggle="collapse" data-target="#collapsibleNavbar" >
            <img src={process.env.PUBLIC_URL + "/images/icons/menu-icon.svg"} alt="" />
          </button>

          <div className="collapse navbar-collapse header-list" id="collapsibleNavbar">
            <ul className="nav navbar-nav navbar-right">

            <li className="nav-item nav-element" data-toggle="collapse">
                <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/order/"
                >
                    Beställ
                </NavLink>
            </li>

              <li className="nav-item nav-element" data-toggle="collapse">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/contact/"
                >
                  Kontakta oss
                </NavLink>
              </li>

            </ul>
          </div>

        </div>
      )
  }
}

const mapStateToProps = state => ({
  user: state.reducerUser
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomHeader);

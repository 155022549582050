import React, {Component} from "react";
import {Link} from "react-router-dom";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import AnchorLink from "react-anchor-link-smooth-scroll";

const interval = { min: 1, max: 25 };

class Home extends Component {

    constructor(props) {

        super(props);

        this.setSlide = this.setSlide.bind(this);

        this.state = {
            slideIndex: 1,
        };

    }

    componentDidMount() {

    }

    setSlide(modifier) {

        let newSlideIndex = this.state.slideIndex + modifier;

        if(newSlideIndex > interval.max) {
          newSlideIndex = interval.min;
        } else if(newSlideIndex < interval.min) {
          newSlideIndex = interval.max;
        }

        this.setState({
            slideIndex: newSlideIndex,
        });
    }


    render() {
        return (
            <div className="wrapper-main">
                <div className="wrapper-index">
                    <CustomHeader/>
                    <Content/>
                </div>
                <hr/>
                <Chapter slideIndex={this.state.slideIndex} setSlide={this.setSlide} />
                <CustomFooter/>
            </div>
        );
    }
}

const Content = () => {
    return (
        <div className="content">
            <div className="content-middle">
                <h1 className="content-title">
                    L-Arse <span className="red">Heat</span> Seat
                </h1>
                <div className="content-text">
                    Smart sittunderlag <span className="red">i</span> ditt vapenfodral
                </div>
                <div className="content-images">
                    <img className="logo-fodral" alt=""
                        src={process.env.PUBLIC_URL + "/images/slideshow/img25.jpg"}
                    />
                </div>
                <div className="content-price">
                    <h1><span className="red">199 kr</span></h1>
                    <p>Inklusive frakt</p>
                    <Link to="/order">
                        <button className="btn btn-green btn-lg">Beställ</button>
                    </Link>
                </div>

            </div>
            <AnchorLink offset="70" href="#target-read-more" className="content-text-readmore">
                <img src={process.env.PUBLIC_URL + "/images/icons/arrow-black.svg"} alt=""/>
                <span>Läs mer</span>
            </AnchorLink>
        </div>
    );
};

const Chapter = (props) => {
    return (
        <div>

            <div className="wrapper-chapter" id="target-read-more">
                <div className="chapter-content">

                    <Slider slideIndex={props.slideIndex} setSlide={props.setSlide} />

                </div>
            </div>

            <hr/>

            <div className="wrapper-chapter" id="target-read-more">
                <div className="chapter-content">

                    <h2>Om L-Arse <span class="red">Heat</span> Seat</h2>

                    <div className="key-container">

                        <div className="key-box key-right">
                            <img src={process.env.PUBLIC_URL + "/images/logos/circle-factory.svg"}
                                 alt=""/>
                            <div className="key-content">
                                <h4>Norrländsk produkt</h4>
                                <p>
                                    Designad och tillverkad i Norrland
                                </p>
                            </div>
                        </div>

                        <div className="key-box key-left">
                            <img src={process.env.PUBLIC_URL + "/images/logos/circle-temp.svg"}
                                 alt=""/>
                            <div className="key-content">
                                <h4>Minimal värmeledning</h4>
                                <p>
                                    Endast 0,05 W/mK värmeledning
                                </p>
                            </div>
                        </div>

                        <div className="key-box key-right">
                            <img src={process.env.PUBLIC_URL + "/images/logos/circle-chair.svg"}
                                 alt=""/>
                            <div className="key-content">
                                <h4>Flera sittalternativ</h4>
                                <p>
                                    6 st sittalternativ
                                </p>
                            </div>
                        </div>

                        <div className="key-box key-left">
                            <img src={process.env.PUBLIC_URL + "/images/logos/circle-security.svg"}
                                 alt=""/>
                            <div className="key-content">
                                <h4>Designskyddad</h4>
                                <p>
                                    Designskyddad i 27 länder
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <hr/>

            <div className="wrapper-chapter">
                <div className="chapter-content">
                    <h1>"Den bästa utrustningen är den du har med dig”</h1>
                    <p>
                        <br/>
                        Min far Anders, var en erfaren jägare och testade under många år utrustning på uppdrag av
                        tidningen Jaktmarker & Fiskevatten. Men han satt alltid på sitt tunna vapenfodral och småfrös
                        på älgpasset... Min mor däremot hade alltid med sig ett sittunderlag på utflykter.
                        <br/>
                        <br/>
                        Med ett smart designat sittunderlag som enkelt fästs i vapenfodralet får du nu en värmande,
                        flexibel och mjuk yta att sitta på.
                        <br/>
                        <br/>
                        En varm jägare är en bättre jägare och troligen också en friskare jägare. Så skydda dig själv
                        från
                        kylan, skydda ditt vapen från skador – men framför allt: Njut mer av din tid i skog och natur.
                    </p>
                </div>
            </div>

            <hr/>

            <div className="wrapper-chapter contact-us">
                <div className="chapter-content">
                    <h2>Tävling</h2>
                    <p className="h2-subheader">
                        Hur många grå prickar ser du i bilden?
                    </p>
                    <img className="img-600" alt=""
                         src={process.env.PUBLIC_URL + "/images/logos/event.png"}
                    />
                    <p className="h2-subheader">
                        Skicka ditt svar till <a className="mailto" href="mailto:tavling@vapenfodral.se">tavling@vapenfodral.se</a> för chans till fina priser.
                    </p>
                </div>
            </div>

            <hr/>

            <div className="wrapper-chapter contact-us">
                <div className="chapter-content">
                    <h2>Vill du veta mer?</h2>
                    <p className="h2-subheader">
                        Om du har någon fråga eller vill beställa denna produkt, kontakta oss gärna!
                    </p>
                    <Link to="/contact">
                        <button className="btn btn-green btn-lg">Kontakta oss</button>
                    </Link>
                </div>
            </div>

        </div>
    );
};

const Slider = (props) => {

    let images = [];

    for (let i = interval.min; i <= interval.max; i++) {
        let displayClass = "";
        if(props.slideIndex !== i) {
            displayClass = "slideshow-hidden"
        }
        images.push(
            <div className={"slideshow-slide " + displayClass} key={"slideshow-slide-"+i} id={"slideshow-slide-"+i}>
                <img src={process.env.PUBLIC_URL + "/images/slideshow/img" + i + ".jpg"} alt=""/>
            </div>
        )
    }

    return (
        <div>
            <div className="slideshow-container">
                {images}
                <a className="slideshow-button slideshow-button-prev" onClick={() => props.setSlide(-1)}>
                  <img src={process.env.PUBLIC_URL + "/images/icons/arrow-black.svg"} alt=""/>
                </a>
                <a className="slideshow-button slideshow-button-next" onClick={() => props.setSlide(1)} >
                  <img src={process.env.PUBLIC_URL + "/images/icons/arrow-black.svg"} alt=""/>
                </a>
            </div>
        </div>
    )
};

export default Home;
